<template>
	<div id="app">
		<router-view class="appView" />
	</div>
</template>
<script>
	export default {
		name: 'Home',
		components: {},
		data() {
			return {}
		},
		watch: {
			//使用watch 监听$router的变化
			// $route(to, from) {
			// 	//如果to索引大于from索引,判断为前进状态,反之则为后退状态
			// 	if (to.meta.index > from.meta.index) {
			// 		//设置动画名称
			// 		this.transitionName = "slide-left";
			// 	} else {
			// 		this.transitionName = "slide-right";
			// 	}
			// },
		},

		created() {
			this.browserType();
		},
		mounted() {},
		methods: {
			// 判断当前浏览器
			browserType() {
				var system = {};
				var p = navigator.platform;
				console.log(p);
				// document.getElementById("test2").innerHTML = p;
				system.win = p.indexOf("Win") == 0;
				system.mac = p.indexOf("Mac") == 0;
				if (system.win || system.mac) {
					//如果是电脑
					console.log('pc');
				} else {
					//如果是手机,
					console.log('移动端');
					window.location.href = 'https://yxzh.pc.life-oasis.com/';
				}
			}
		}
	}
</script>
<style lang="scss">
	@import url('./assets/css/reset.css');

	.globalLoading {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 9999;
		background-color: rgba(0, 0, 0, .5);
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#app {
		background-color: aliceblue;
	}
</style>